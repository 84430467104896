import React, { Suspense } from 'react';
import { Spin } from 'antd';
import RouteList from './RoutesList';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainHeader from '../layout/MainHeader';

export default function RouteComponent() {
    return (
        <Router>
            <Suspense fallback={<div className="spin-loader" ><Spin size="large"></Spin></div>}>
                <Switch>
                    {
                        RouteList().map((route, index) => (
                            <Route
                                key={index}
                                exact path={route.path}
                                render={props =>
                                    <route.layout>
                                        {route.component()}
                                    </route.layout>

                                }
                            // key={index}
                            // exact path={route.path}
                            // component={route.component}
                            />

                        ))
                    }
                </Switch>
            </Suspense>
        </Router>
    )
}

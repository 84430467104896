import React from "react";
const HomePage = React.lazy(() => import('../../pages/home/Home'))
const FullLayout = React.lazy(() => import('../layout/FullLayout'));
const Certificate = React.lazy(() => import('../../pages/certificate/Certificate'));
const VaccinationCertificate = React.lazy(() => import('../../pages/prints/VaccinationCertificate'))

export default function RouteList() {
    return [
        {
            path: "/",
            layout: FullLayout,
            component:()=> <HomePage/>
        },
        {
            path: "/certificate/:id",
            layout: FullLayout,
            component:()=> <Certificate/>

        },
        {
            path: "/vaccination/certificate/print/:tei",
            layout: FullLayout,
            component:()=> <VaccinationCertificate/>

        }
    ]
}

import React, { useState, useEffect } from 'react';
import '../../pages/home/Home.css';
import '../../assets/css/bootstrap-icons.css';
import '../../assets/css/bootstrap.min.css';
import '../../assets/lib/animate/animate.css';
import '../../assets/lib/animate/animate.min.css';
import logo from '../../assets/img/logo-Ministerio-de-Saude.png';

export default function MainHeader() {
    const [isSticky, setIsSticky] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 50) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <nav className={isSticky ? 'navbar navsticky navbar-expand-lg shadow py-1 px-2' : 'navbar navbar-expand-lg shadow py-1 px-2'}>
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img
                        src={logo}
                        className="logo img-fluid"
                        alt=""
                        style={{ width: 70 }}
                    />
                </a>
                <button
                    className={`navbar-toggler ${isNavOpen ? 'collapsed' : ''}`}
                    type="button"
                    onClick={toggleNav}
                    aria-expanded={isNavOpen ? 'true' : 'false'}
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="/">
                                Início
                            </a>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle"
                                id="navbarLightDropdownMenuLink"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={toggleNav}
                            >
                                Acesso às plataformas
                            </a>
                            <ul
                                className={`dropdown-menu dropdown-menu-light ${isNavOpen ? 'show' : ''}`}
                                aria-labelledby="navbarLightDropdownMenuLink"
                            >
                                <li>
                                    <a
                                        className="dropdown-item"
                                        target="_blank"
                                        href="https://dhis2.gov.st/sis"
                                    >
                                        SIS
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="dropdown-item"
                                        target="_blank"
                                        href="https://dhis2.gov.st/sive"
                                    >
                                        SIVE
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="dropdown-item"
                                        target="_blank"
                                        href="https://dhis2.gov.st/tracker"
                                    >
                                        TRACKER
                                    </a>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
}
